#adminHomeStyle{
    h1 {
        font-size: 24px;
        font-weight: bold;
        color: #434;
    }
    .dFlex{
        display: flex;
        align-items: center;
        gap: 50px;
    }
    .dFlex1{
        display: flex;
        align-items: center;
    }
    .greyBack {
        background: #f7f7f7;
        padding: 10px 10px;
        border-radius: 15px;
    }
    .greyBack img {
        width: 45px;
        height: auto;
    }
    .greyBack  h3{
        font-size: 19px;
        font-weight: 700;
    }
    .greyBack  h3 span{
        color: #00699f;
    }
    .greyBack1 {
        background: #f7f7f7;
        padding: 20px 10px;
    }

    .greyBack1  h3{
        font-size: 19px;
        font-weight: 700;
        margin-right: 20px;
    }
    .greyBack1 h3 span{
        color: #00699f;
    }
    .table {
        border-collapse: collapse;
        width: 100%;
      }
    .table, th {
        border: 1px solid #606e84;
        background: #f0f0f0;
        line-height: 1.5;
        vertical-align: middle !important;
        padding: 5px;
        font-size: 14px;
        font-weight: 700;
    }
    .table, th  p{
        font-size: 14px;

    }
    .table, td {
        border: 1px solid black;
        background: #fff;
        border-collapse: collapse;
        vertical-align: middle !important;
        font-size: 13px;
        font-weight: 500;
        padding: 5px;
      }

}
.adminTopBody{
    box-sizing: border-box;
    display: flex;
    width: 100%;
    margin: 0;
}

#openjob .step1 h1 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

#openjob .step1 h3 {
    font-size: 15px;
    font-weight: 700;
    color: #757575;
    margin-bottom: 5px;
    margin-top: 30px;
}
#openjob .step1 h3 span {
    font-weight: 400;
}


#openjob .step1 .bordered {
    border: 1px solid grey;
    padding: 10px;
    margin-top: 5px;
}

#openjob .step1 .bordered1 {
    border: 1px solid grey;
    margin-top: 5px;
}

#openjob .step1  input{
    margin-bottom: 20px;
    border: 1px solid #dfe2e6;
    border-radius: 5px;
}
#adminJobList .modal_box {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2147483647;
    margin: 0 !important;
}

#adminJobList .modal_inner {
    max-height: 90vh;
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 0;
    background-color: #F7F8F8;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    overflow: scroll;
}

#adminJobList .modal_box label {
    
}

#openjob .step1  label{
    margin-bottom: 10px;
    display: block;
}

#openjob .step1  select{
    margin-bottom: 20px;
    border: 1px solid #dfe2e6;
    border-radius: 5px;
    width: 300px;
}

#openjob .step2 h1 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

#openjob .step2 h3 {
    font-size: 15px;
    font-weight: 700;
    color: #757575;
    margin-bottom: 5px;
    margin-top: 30px;
}

#openjob .step2 h3  span{
    font-weight: 400;
}


#openjob .step2 .bordered {
    border: 1px solid grey;
    padding: 10px;
    margin-top: 5px;
}

#openjob .step2  input{
    margin-bottom: 20px;
    border: 1px solid #dfe2e6;
    border-radius: 5px;
}

#openjob .step2  label{
    margin-bottom: 10px;
    display: block;
}

#openjob .step2  select{
    margin-bottom: 20px;
    border: 1px solid #dfe2e6;
    border-radius: 5px;
    width: 300px;
}

#openjob .step2 .dFlex{
    display: flex;
    align-items: center;
}
#openjob .step2 .dFlexWrap {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    background-color: #efefef;
    margin-bottom: 20px;
}

#openjob .step3 h1 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 20px;
}
#openjob .step3 h3 {
    font-size: 15px;
    font-weight: 700;
    color: #757575;
    margin-bottom: 5px;
    margin-top: 30px;
}
.time_selector{
    margin-top: 5px;
}

#openjob .time_box p {
    margin: 0 !important;
    padding: 0 15px;
}

#openjob .step3 h3 span {
    font-weight: 400;
}


#openjob .step3 .bordered {
    border: 1px solid grey;
    padding: 10px;
    margin-top: 5px;
}

#openjob .step3  input{
    margin-bottom: 20px;
    border: 1px solid #dfe2e6;
    border-radius: 5px;
}

#openjob .step3  label{
    margin-bottom: 10px;
    display: block;
}

#openjob .step3  select{
    margin-bottom: 20px;
    border: 1px solid #dfe2e6;
    border-radius: 5px;
    width: 300px;
}

#openjob .imageBtn{
    color: #ffffff;
    border-radius: 5px;
    background: #00699f;
    padding: 10px 25px;
    font-size: 14px;
}
#openjob .mb30{
    margin-bottom: 30px;
}


#openjob .step3 .dFlex{
    display: flex;
    align-items: center;
}

#openjob table .dFlex{
    display: flex !important;
    align-items: normal !important;
    flex-wrap: wrap !important;
    /* margin-bo
    ttom: 10px; */
}
#openjob .radio {
    transform: scale(1.5);
    margin-right: 10px;
}
#openjob table .dFlex div {
    padding-right: 3px;
    /* padding-top: 5px; */
}
#openjob .alldatabtn{
    background: #00699f;
    color: #ffffff;
    margin-left: 20px;
    height: 40px;
    width: 370px;
    margin-bottom: 20px;
    border-radius: 5px;

}


#openjob .orangeBtn {
    color: #ffffff;
    border-radius: 5px;
    background: #f97b34;
    font-size: 14px;
    padding: 5px 15px;
    margin-right: 20px;
}
#openjob .step3 .deleteBtn img{
    width: 15px;
    margin-right: 5px;
}

#openjob table tr td:first-of-type p{
    font-weight: 700;
}
#openjob .m-3 {
    margin: 0px 5px !important;
}

#openjob .step3 .adminImageBox img {
    width: 60px;
    margin-right: 20px;
}

#openjob .step3  input[type="file"] {
    display: none;
}

#openjob .step3 .image_dFlex{
    display: flex;
}
#openjob .detailDflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
#openjob .detailTextBox h4 {
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 34px;
}
#openjob .detailTextBox h4  span{
    font-size: 12px !important;
    font-weight: 500 !important;
}
#openjob .detailTextBox .borders {
    border: 1px solid #00a0e9;
    padding: 5px 15px;
    border-radius: 15px;
    color: #00a0e9;
}

#openjob  .borders2 {
    border: 1px solid #00699f;
    padding: 5px 15px;
    border-radius: 5px;
    color: #00699f;
}

#openjob  .borders1 {
    border: 1px solid #00699f;
    padding: 5px 15px;
    border-radius: 5px;
    color: #00699f;
    display: inline-block;
    margin:  0 !important;
}
#openjob .blueText {
    color: #00699f;
    font-weight: 700;
    margin-right: 10px;
}
#openjob .wid150:first-of-type {
    font-size: 14px;
    font-weight: 700;
}

#openjob .wid80  p{
    margin-top: 2px;
}

#openjob .wid150 p {
    margin-top: 2px;
}

#openjob .imgBox {
    max-width: 150px;
}

#openjob .imgBoxAdmin  img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    overflow: hidden;
}
.wid150{
    width: 30%;
}
.wid80{
    width: 70%;
}
.wid50{
    width: 50%;
    word-break: break-word;
}
/* #newsTable th, td {
    width: 300px;
} */

.free_tag_input_area {
    display: flex;
    align-items: start;
    border-radius: 4px;
    overflow: hidden;
    max-width: 400px; /* Adjust this as needed */
}

.free_tag_input_area .input-field {
    flex-grow: 1;
    border: none;
    padding: 8px;
    outline: none;
}

.free_tag_input_area .add-button {
    background-color: #007BFF; /* Adjust color to match your design */
    color: white;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    outline: none;
    font-weight: bold;
}

.free_tag_input_area .add-button:hover {
    background-color: #0056b3; /* Darken the button on hover */
}

.free_tag_input_area .input-field::placeholder {
    color: #aaa;
}

.free_tag_input_area input {
    border: none;
    font-size: 14px;
    color: #333;
}

.free_tag_input_area input:focus {
    outline: none;
}

.free_tag_input_area .add-button:focus {
    outline: none;
}

.openJobDetail table th,.openJobDetail table td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .openJobDetail table {
    width: 100%;
  }
  
.openJobDetail  th,.openJobDetail td {
    padding: 10px;
    text-align: left;
  }

  .openJobDetail  td:first-of-type {
    width: 20%;
  }

  #openjob  .deletBtnToggle {
    background: red;
    color: white;
    padding: 5px 10px;
    margin-left: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
  }


#openjob .step3 .custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #0073a6;
    color: white;
    border-radius: 4px;
    font-size: 12px;
}

#openjob .step3 .fileDeleteBtn {
    display: inline-block;
    padding: 12px 15px;
    cursor: pointer;
    background-color: #ff6767;
    color: white;
    border-radius: 4px;
    font-size: 12px;
    text-wrap: nowrap;
    margin-left: 5px;
    margin-bottom: 20px;
}

#openjob .step3 .newImage {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #ffffff;
    color: #00699f;
    border-bottom: 1px solid #00699f;
    font-size: 12px;
    text-wrap: nowrap;
    margin-left: 5px;
    margin-bottom: 20px;
}



#openjob .step3 .custom-file-upload:hover {
    background-color: #005f85;
}


/* #openjob .step1 form input{
    margin-bottom: 20px;
    border: 1px solid #dfe2e6;
    border-radius: 5px;
    width: 300px;
} */
.wid300{
    width: 300px !important;
}
.time_box {
    display: flex;
    align-items: center;
}

.time_box  select{
    margin: 0 !important;
}
#openjob .step1 .flexBox{
    padding: 15px;
    display: flex;
}
.m-0 {
    margin: 0 !important;
}
.check_list {
display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.mt20{
    margin-top: 20px;
}

#openjob .step1 .dFlex{
    display: flex;
    align-items: center;
}
#openjob .step1 .dFlex button{
    background: #00699f;
    color: #ffffff;
    margin-left: 20px;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 5px;
}
#openjob .step1 .flexBox .mr{
    margin-right: 100px;
}




  .cont_boxAdmin {
    padding: 20px;
    border-radius: 5px;
  }
  #news .body {
    max-width: 800px;
  }
  #news button {
    width: auto !important;
  }
  #news input {
    margin-bottom: 20px !important;
  }
  #news label {
    margin-bottom: 10px !important;
    font-weight: 700;
    color: #757575;
    display: block;
  }
  

  #adminJobList .adminPageWrapper > .header {
    border-bottom: 2px solid #747474;
  }
  #adminHome .adminPageWrapper > .header {
    border-bottom: 2px solid #747474;
  }
  
.textWrapper {
    margin-bottom: 20px;
  }
  .newsBtnToggle {
    background: #ffffff;
    color: #00699f;
    border-bottom: 1px solid #00699f;
    margin-bottom: 20px;
  }

  .newsBtnBLack {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 15px;
    color: #000;
    text-align: center;
    border: 1px solid #000;
    background: #ffffff;
    font-size: 12px;
    height: auto;
  }
  .invoiceBtn {
    width: auto;
    border-radius: 6px;
    border: 2px solid #00A0E9;
    background: #00A0E9;
    color: #FFF;
    text-align: center;
    line-height: normal;
  }
  .newsBtnToggle:hover {
    color: #ffffff;
    background: #00699f;
  }
  
  .blueBtn {
    color: #007bff;
    cursor: pointer;
    text-decoration: none;
  }
  
  .nest1 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .nest1 dt{
    padding: 10px;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    display: flex;
    align-items: center;
    width: 50%;
    box-sizing: border-box;
  }

  .nest1 dd {
    padding: 10px;
    border-top: 1px solid #ccc;
    /* border-left: 1px solid #ccc; */
    border-right: 1px solid #ccc;
    display: flex;
    align-items: center;
    width: 50%;
    box-sizing: border-box;
  }

  .nest1 dd  ul{
    margin: 0 !important;
  }
  .nest1 dd:last-of-type {
    border-bottom: 1px solid #ccc;
  }

  .nest1 dt:last-of-type {
    border-bottom: 1px solid #ccc;
}


  
  
  .nest1 dt {
    background-color: #f0f0f0;
    text-align: left;
  }
  
  .nest1 dd {
    background-color: #fff;
    color: #000;
  }
  
  .nest1 dt, .nest1 dd {
    display: inline-block;
  }
  
  .nest1 dt {
    flex: 0 0 20%;
  }
  
  .nest1 dd {
    flex: 0 0 80%;
  }
.form {
    max-width: 500px;
    position: relative;
    display: block;
    margin-top: 50px;
}

.form-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.form-groups {
    margin-bottom: 25px;
}

.form-groups label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #757575;
}

.form-groups input,
.form-groups select {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
}

.form-groups .requireds {
    color: red !important;
    font-size: 1em;
    background: none !important;
}

/* button {
    width: auto;
    padding: 10px;
    background-color: #007bff;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    min-width: 150px;
} */

/* button:hover {
    background-color: #0056b3;
} */
.blueBtn {
    color:#05a1e9  !important;
}
.recruitmentImg {
    /* width: 100px; */
    height: auto;
}
.recruitmentImg img {
    width: 50px !important;
    /* border-radius: 10px !important; */
}

.typeSelect {
    display: math !important;
    width: auto !important;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

/* Add an arrow icon to the select dropdown */
.typeSelect {
    /* background: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjNjY2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICA8cGF0aCBkPSJNMTIuMDEsMTUuOTggbC01LjY1LTUuNjVhMS4wMSwxLjAxIDAgMCAxIC0uMDAxLTEuNDEgbDEuNDEsLTEuNDFhMS4wMSwxLjAxIDAgMCAxIDEuNDEsMCBMMTIsMTMuMjEgTDE0LjgzLDkuMzkgYTEuMDEsMS4wMSAwIDAgMSAxLjQxLDAuMDAxIGwxLjQxLDEuNDFhMS4wMSwxLjAxIDAgMCAxIC0uMDAxLDEuNDFsLTUuNjUsNS42NUEuOTk5Ljk5OSAwIDAgMSAxMi4wMSwxNS45OCIgLz4KPC9zdmc+Cg==') no-repeat right 10px center; */
    background-size: 15px;
}

/* Focus and hover styles */
.typeSelect:focus, .typeSelect:hover {
    border-color: #999;
    outline: none;
}

/* Option styling */
.typeSelect option {
    padding: 10px;
    font-size: 16px;
    background-color: #fff;
    color: #333;
}
.searchBtn {
    padding: 10px;
    border-radius: 5px;
    background: #111111;
    color: #FFFFFF;
    font-size: 12px;
    margin-left: 10px;
}
.detailBtn {
    padding: 5px;
    border-radius: 5px;
    color: #FFFFFF !important;
    text-align: center !important;
    background: #296292;
    font-size: 12px;
}
.detailBtnAdmin {
    padding: 5px;
    border-radius: 5px;
    color: #FFFFFF !important;
    text-align: center !important;
    background: #296292;
}

.detailBtnOrange {
    width: auto !important;
    background: #ffffff !important;
    padding: 5px 10px;
    border-radius: 15px;
    /* color: #FF7E00 !important; */
    text-align: center !important;
    border: 1px solid #FF7E00 !important;
    font-size: 12px;
    display: inline-block;
}
.openTable .bordered {
    padding: 5px;
    border: 1px solid #7e7a7a;
    border-radius: 15px;
    text-align: center;
    /* width: 100px; */
}



.detailBtnBlue {
    background: #ffffff !important;
    padding: 5px 10px;
    border-radius: 15px;
    color: #00699F !important;
    text-align: center !important;
    border: 1px solid #00699F;
    font-size: 12px;
    display: inline-block;
}

.detailBtnRed {
    width: auto !important;
    background-image: url("../../../public/assets/img/icon_arrow.svg") !important;
    appearance: none;
    background-color: white;
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;
    padding: 5px 10px;
    border-radius: 15px;
    text-align: center !important;
    border: 1px solid #FF6767;
    font-size: 12px;
    display: inline-block;
    border: 1px solid #FF6767 !important;
}
.invoiceBackToListBtn {
    width: 80%;
    height: 52px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 2px solid #00A0E9;
    background: #fff;
    color: #00A0E9;
    text-align: center;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.52px;
    text-transform: uppercase;
}
.adminErrorTextRed {
    color: red;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.detailBtnBlack {
    width: auto !important;
    background-image: url("../../../public/assets/img/icon_arrow.svg") !important;
    appearance: none;
    background-color: white;
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;
    padding: 5px 10px;
    border-radius: 15px;
    color: #333333 !important;
    text-align: center !important;
    border: 1px solid #333333;
    font-size: 12px;
    display: inline-block;
}

.newsBtnBlue {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 15px;
    color: #00699F;
    text-align: center;
    border: 1px solid #00699F;
    background: #ffffff;
    font-size: 12px;
    height: auto;
}

.newsBtnOrange {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 15px;
    color: #FF7E00;
    text-align: center;
    border: 1px solid #FF7E00;
    background: #ffffff;
    font-size: 12px;
    height: auto;
}

.roleBtn {
    border: 1px solid #9b8e8e;
    background: #FFFFFF !important;
    color:  #111111 !important;
    border-radius: 15px;
    padding: 5px 15px 5px 15px;
    min-width: 100px;
    font-size: 12px;
}
.red {
    padding: 5px;
    background: #ff6767;
    color: #FFFFFF !important;
    text-align: center;
    border-radius: 5px;
    width: auto !important;
}

.newsBtnBig {
    padding: 10px;
    border-radius: 5px;
    color: #FFFFFF;
    background: #00A0E9;
    font-size: 16px;
    width: 100%;
    height: auto;
}
.openBtn {
    width: 100% !important; 
    height: auto;
    border-radius: 5px !important;
    white-space:nowrap;
}
.csvButton {
    width: auto;
    padding: 10px;
    background-color: #f97b34;
    color: #FFFFFF;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 12px;
}
.deleteBtn {
    width: auto;
    padding: 10px;
    background-color: #ff6767;
    color: #FFFFFF;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 12px;
}
.d-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.d-flex img {
    width: 20px !important;
    height: auto;
}

.createBtn {
    width: auto;
    padding: 10px;
    background-color: #00a0e9;
    color: #FFFFFF;
    border-radius: 5px;
    font-size: 14px;
}
.table {
    border-collapse: collapse;
    width: 100%;
  }
  #parachannel .adminTable1Wrapper .openTable td, #parachannel .adminTable1Wrapper .table th {
    border: 1px solid #606e84;
    line-height: 1.5;
    vertical-align: middle !important;
}
.invoiceDetail {
    margin: 20px 50px 20px 50px;
}

.invoiceDetail .titleInvoice {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 20px;
}
.invoiceDetail .invoiceDtlBtn {
    margin: 20px auto;
    display: block;
    padding: 10px 25px 10px 25px;
    border-radius: 5px;
    background-color: #00A0E9;
    color: #fff;
}


.invoiceDetail .table-container {
    max-width: 1000px;
    background: #fff;
    border-radius: 0;
    overflow: hidden;
  }

  .invoiceDetail .dFlex {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }

  .invoiceDetail .dFlex  label input{
    border: 1px solid #ddd !important;
    border-radius: 5px;
    margin-top: 10px !important;
  }

  .invoiceDetail .dFlex  label{
    margin-bottom: 10px;
  }
  
  .invoiceDetail .invoice-detail-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    margin: 0;
  }
  

  
  .invoiceDetail .invoice-detail-table tbody td {
    padding: 10px;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    font-size: 16px;
  }

  .invoiceDetail .invoice-detail-table tbody td:first-of-type  {
    width: 200px;
  }

  
  
  .invoiceDetail .invoice-detail-table tbody .total-row td {
    font-weight: bold;
    background-color: #fdf4e5;
  }
  .tableColor {
    background-color: #f2f2f2;
  }

  @media screen and (max-width: 768px) {
    .invoiceDetail .table-container {
        max-width: auto !important;
        margin: 20px 50px 20px 50px;
      }
  }
.openTable{
    /* white-space: nowrap; */
}
.sectionLeft{
    width: 250px;
    height: 100vh;
    flex-shrink: 0;
    background: #FDD956;
    padding-top: 40px;
}

#nav-menu{
    height: 100%;
}

.load-spinner-mail {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .absimg {
    position: absolute;
    width: 100px;
    height: auto;
    top: 29%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .backLoad {
    padding-top: 100px;
    position: relative;
    display: block;
    background: #E0F5FF !important;
  }
  .loader-text {
    text-align: center;
  }
  .loader-text h1 {
    font-size: 25px;
    margin-bottom: 15px;
    font-weight: 600;
  }
  .loader-text p {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .loader-text button {
    width: 175px;
    height: auto;
    padding: 15px;
    color: white;
    background: #00afe9;
    border-radius: 5px;
    font-weight: 600;
  }
  .spinner-mail {
    width: 200px;
    height: 200px;
    border: 10px #00afe9 solid;
    border-top: 8px #00afe13d solid;
    border-radius: 50%;
    animation: sp-anime 0.8s infinite linear;
    margin-bottom: 20px;
  }

.navSection ul li span{
    color: #00699F !important;
    text-align: center !important;
    font-family: Montserrat !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    letter-spacing: -0.225px !important;
    width: 250px !important;
    height: 56px !important;
    flex-shrink: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

#adminLogo{
    width: 92px !important;
    height: 80px !important;
    flex-shrink: 0;
    margin-bottom: 40px;
    margin-left: 79px;
    margin-right: 79px;
}

#sectionLeftBtn{
    width: 130px !important;
    height: 40px !important;
    flex-shrink: 0 !important;
    color: #18A0FB !important;
    text-align: center !important;
    font-family: Montserrat !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    letter-spacing: -0.225px !important;
    border-radius: 6px !important;
    border: 1px solid  #18A0FB !important;
    background: #FFF !important;
    position: absolute;
    bottom: 30px;
    left: 25%;
}

.navSection{
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#nav-menu ul li .navLink{
    display: flex;
    justify-content: center;
    align-items: center;
}





/* Home */

.sectionRight{
    /* width: 100% !important; */
    background-color: #F6F6F6 !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 93vh;
}

.navi{
    display: flex;
    width: 100%;
    padding: 18px 1060px 19px 80px;
    align-items: center;
    background: #18A0FB;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
}

#sectionRightP{
    display: flex;
    /* width: 74.375rem; */
    padding: 1.125rem 66.25rem 1.1875rem 5rem;
    align-items: center;
    background-color: #18A0FB;
    color: #FFF;
    text-align: center;
    font-family: Hiragino Kaku Gothic Pro;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.01406rem;
}


.adminCard{
    /* height: 180px !important; */
    flex-shrink: 0 !important;
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    padding: 16px 40px!important;
    background-color: white !important;
    border-radius: 8px;
    margin: 1%;
    width: 210px;
}
.adminCardLogo {
    display: flex;
}


.adminCard img{
    width: auto !important;
    height: 100px !important;
    margin-bottom: 5px;
}

.adminCard p{
    color: #000 !important;
    text-align: center !important;
    font-family: Montserrat !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    letter-spacing: -0.3px !important;
}

.naviDown{
    padding: 24px;
    width: 100% !important;
    /* display: flex; */
}
.pricespan{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;

}
.checkboxP{
    line-height: normal;
    letter-spacing: 1px;
}
.adminH3{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
}

.adminY{
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: black;

}
.adminNTheadTh{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: black;
    padding: 1%;
    text-align: center;
    border: 1px solid #C4C4C4;
}

.adminNThead{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    background: white;
}
.nP{
    margin: 3%;
}

.tdN{
    text-align: center;
    padding: 1%;
    background-color: #E9E9E9;
    border: 1px solid #C4C4C4
}

.tdLastPrice{
    text-align: center;
    padding: 1%;
    background-color: #E9E9E9;
    border-left: 1px solid #C4C4C4;
    border-right: 1px solid #C4C4C4;
}

.tdLastTotalPrice{
    text-align: center;
    padding: 1%;
    background-color: #E9E9E9;
    border-left: 1px solid #C4C4C4;
    border-right: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
}

.ap{
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;

}
.adminNSelect{
    width: 180px !important;
    height: 32px !important;
    background: url(/public/assets/img/company/icon11.png) no-repeat !important;
    background-size: 10px !important;
    background-position: right 15px center !important;
    border: 1px solid;
    border-radius: 5px !important;
    cursor: pointer !important;
    margin-right: 40px !important;
    margin-bottom: 10px !important;
}
.newP{
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #B2B2B2;
}

@media screen and (max-width: 768px) {
    .newP{
        font-family: Inter;
    font-size: 6px;
    font-weight: 500;
    line-height: 7.26px;
    text-align: left;

    }
    .plandetailp{
        color: #333;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    }
  }
.nBtn{
    width: 130px;
    height: 32px;
    top: 108px;
    left: 964px;
    border-radius: 8px;
    border: 1px solid #00A0E9;
    color: #00A0E9;
}

.nBtn ::before{
    content: url(/public/assets/img/correct.png) !important;
    color: #00A0E9 !important;
}


/* Scouts */

.adminInputR{
    width: 20px !important;
    height: 20px !important;
    outline: 1px solid #D9D9D9;
}

.adminSelect1{
    width: 180px !important;
    height: 32px !important;
    background: url(/public/assets/img/company/icon11.png) no-repeat !important;
    background-size: 10px !important;
    background-position: right 15px center !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    margin-right: 12px !important;
    margin-bottom: 10px !important;
}

.adminOption p::before{
    content: url(/public/assets/img/seigen.png) !important;
}

/* .adminCheckbox{
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
} */


.adminH1{
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.48px;
}

.adminH2{
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.3px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.scoutSection{
    padding-left: 56px !important;
    padding-right: 56px !important;
    padding-top: 71px !important;
    width: 80%;
}

.adminTr{
    display: flex !important;
    justify-content: space-between !important;
}


.adminTh{
    height: 48px;
    color: #C4C4C4;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
}

.adminLabel{
    color: #000;
    font-family: Inter !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    padding-top: 5px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.adminActive {
    /* width: 15.625rem !important; */
    border: none !important;
    height: 3.5rem !important;
    flex-shrink: 0 !important;
    background: #00699F !important;
    color: #FFF !important;
    text-align: center !important;
    font-family: Montserrat !important;
    font-size: 0.9375rem !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: -0.01406rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 0px !important;
    width: 100% !important;
}

.adminSpan{
    border: none !important;
    width: 100% !important;
    color: #00699F !important;
    text-align: center !important;
    font-family: Montserrat !important;
    font-size: 0.9375rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    letter-spacing: -0.01406rem !important;
}

#linkH1{
    width: 170px !important;
    display:flex !important; 
    justify-content:center !important; 
    align-items :center !important; 
    margin:50px !important;
}
.adminNavWrapper{
    background: #FDD956;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btn-logout{
    background: whitesmoke;
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

@media print, screen and (max-width: 768px) {

    #linkH1{
        display:flex !important; 
        justify-content:center !important; 
        align-items :center !important; 
        margin:15px !important;
    }

    #sectionLeftBtn{
        position:static !important; 
    }

    #sectionRightP{
        height: 50px;
    }

    #adminHome{
        display: block !important;
        margin-bottom: 0 !important;
    }

    #sectionRightP{
        width: 100% !important;
        padding: 0 !important;
        padding-left: 20px !important;
    }

    .adminCard{
        margin:8px !important;
    }
}
:root{
    height: 100% !important;
    width: 100% !important;
}

@media screen and (max-width: 1440px) {
    .adminCard{
        margin: 24px;
        flex-shrink: 0 !important;
        display: flex !important;
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
        padding: 12px 21px!important;
        background-color: white !important;
        border-radius: 8px;
        width: 175px;
    }


    #sectionRightP{
        width: 100% !important;
        padding: 0 !important;
        height: 50px;
        padding-left: 20px !important;
    }
  }
@media screen and (max-width: 1380px) {
    .adminNavBar{
        width: 257px;
    }
    .adminCard{
        width: 160px;
        padding: 16px 16px!important;

    }
  }

@media(max-width:1200px) {
    .sectionRight{
        height: 100%;
    }
    .naviDown{
        padding: 0;
        width: 100% !important;
        /* display: flex; */
    }
    .adminCardLogo {
        display: block;
        width: auto;
    }
    .adminCard{
        width: 100%;
    }
}



.notActive{
    border: none !important;
    height: 3.5rem !important;
    flex-shrink: 0 !important;
    /* background: #00699F !important; */
    color: #00699F !important;
    text-align: center !important;
    font-family: Montserrat !important;
    font-size: 0.9375rem !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: -0.01406rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 !important;
}

/* Scouts*/
.adminTable{
    /* max-width: 100%; */
    width: 100%;
    /* margin: auto; */

}

.scroll{
    width: 100%;
    overflow: scroll;
    max-width: 100%;

    height: 700px;
}

.adminThead{
    width: 100%;
    background: #484848;
    color:#FFF;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    z-index: 100;
}

.adminThead th{
    padding: 8px;
    text-wrap: nowrap;
    border: 1px solid darkgray;
    vertical-align: middle;
}

.adminTbody{
    color: #1E1E1E;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    text-align: center;
}

.adminTbody td{
    /* text-wrap: nowrap; */
    padding: 8px;
    border: 1px solid darkgray;
    vertical-align: middle;
}

.adminPopupH1{
    color: #333;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.adminPopupSelect{
    width: 160px !important;
    height: 40px !important;
    flex-shrink: 0 !important;
    border: 1px solid #C4C4C4 !important;
    background: url(/public/assets/img/company/icon11.png) no-repeat !important;
    background-size: 10px !important;
    background-position: right 15px center !important;
    cursor: pointer !important;
    margin-right: 40px !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    color: #00A0E9 !important;
    font-family: Inter !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
}

.adminTdH2{
    color: #18A0FB !important;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
}

.adminScoutsBtn{
    width: 140px;
    height: 40px;
    flex-shrink: 0;
    background: #00A0E9;
    color: var(--, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    
}
.adminScoutsBtn1{
    width: 140px;
    height: 40px;
    flex-shrink: 0;
    width: 140px;
    height: 40px;
    flex-shrink: 0;
    color: #8E8E8E;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.adminMakeScoutsBtn{
    width: 140px;
    height: 32px;
    flex-shrink: 0;
    background: #F05A24;
    color:#FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.starRightBtn{
    width: 140px;
    height: 32px;
    flex-shrink: 0;
    border: 1px solid #00A0E9;
    color: #00A0E9;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.adminTdH2s{
    color: #1E1E1E;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
}

.adminTdH2d{
    color: #FC3737;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
}

.adminTheadTh{
    text-align: center;
}

.adminTbodyNum{
    color: blue;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
}

.scoutsBtn{
    width: 200px;
    height: 52px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 2px solid #00A0E9;
    background: #00A0E9;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.52px;
    text-transform: uppercase;
}

@media screen and (max-width: 768px) {
    .scoutsBtn{
        width: 100%;
        height: 52px;
        flex-shrink: 0;
        border-radius: 6px;
        border: 2px solid #00A0E9;
        background: #00A0E9;
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.52px;
        text-transform: uppercase;
    }
  }

.invoicePrintBtn{
    width: 80%;
    height: 52px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 2px solid #00A0E9;
    background: #00A0E9;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.52px;
    text-transform: uppercase;
}

.invoiceDetailBtn{
    width: 80%;
    height: 52px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 2px solid #00A0E9;
    background: #FFF;
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.52px;
    text-transform: uppercase;
}

.btn{
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: center;
}


#scoutsInputBtn{
    width: 100px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #00A0E9;
    color:  #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    margin-right: 30px;
    margin-bottom: 10px;
}
#scoutsInputClrBtn{
    color: #FFF;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* line-height: normal; */
    letter-spacing: -0.21px;
    width: 160px;
    height: 32px;
    flex-shrink: 0;border-radius: 8px;
    background: #00A0E9;
    padding-top: 0.5%;
}
#scoutsInputClrBtn ::before{
    content: url(/public/assets/img/correct.png) !important;
    color: white !important;
    /* left: 10 !important; */
}
.srtTbody{
    text-align: center;
}

.srtTbody::after{
    content: url(/public/assets/img/arrow.png);
    background-repeat: no-repeat;
    
}

.adminTrTitle{
    color: #C4C4C4;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
}



/*scoutsTtitle*/

.scoutsTtitle{
    width: 100%;
    background: #00A0E9;
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.scoutsH2{
    color: #000;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}


.scoutsSp{
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

}

.scoutsH4{
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.scoutsActive{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 140px !important;
    height: 32px !important;
    flex-shrink: 0 !important;
    background: #00A0E9;
    color: #FFF !important;
    font-family: Inter !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    margin-right: 20px;
}

.scoutsNotActive{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 140px !important;
    height: 32px !important;
    flex-shrink: 0 !important;
    border: 1px solid #D9D9D9 !important;
    color: #D9D9D9 !important;
    font-family: Inter !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    margin-right: 20px;
}

.scoutsButton{
    width: 140px;
height: 32px;
flex-shrink: 0;
background: #00A0E9;
color: #FFF;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
}


.adminJobsTop{
    /* width: 50%; */
    display: flex;
    justify-content: start;
}

.sectionScouts{
    height: 110vh;
}

.invoiceDetailHeight{
    height: 110vh;
}

.btnFont{
    line-height: 2.4;
}
.checkScale15{
    scale: 1.5;
}
.scountBgCompany{
    background-color: lightgray;
}
.text-center{
    text-align: center;
}
.mb-4{
    margin-bottom: 24px;
}
.border-blue{
    border: 1px solid #18A0FB;
}
.border-red{
    border: 1px solid #FF4D4D;
}

@media screen and (max-width: 1520px) {
    .adminJobsTop{
        justify-content: start !important;
        width: 100% !important;
    }
  }


  @media screen and (min-width: 769px) {
    .sectionScouts {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .adminJobsTop {
      margin: auto;
      flex-direction: row;
      justify-content: space-between;
    }
  
    .adminInputR,
    .adminSelect1,
    #scoutsInputBtn,
    #scoutsInputClrBtn {
      width: auto;
      margin-bottom: 0;
    }
  
    .adminH2 {
      font-size: 1.5em;
    }
  
    .scroll {
      height: 500px;
      overflow-y: auto;
    }
  
    .adminTable {
      font-size: 16px;
    }
  
    .pagination_circle {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .thNew{
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  .tdNew {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
  }

  .newPageDetail1H1{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;

  }

  /* .newPageDetail1Section{
    display: flex;
    justify-content: space-around;
  } */

  @media screen and (max-width: 780px) {
    /* .newPageDetail1Section{
        display: block;
    } */

    .newPageDetail1H1{
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: left;
    
      }
  }

@media only screen and (min-width: 320px) and (max-width: 499px) {
    .cont {
        padding-right:15%;
        padding-left:15%;
    }
    .newPageDetail1Section{
        display: block;
        /* justify-content: space-around; */
    }
    
    .price{
        font-family: Inter;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.73px;
        color: black;
        position: absolute;
        top: 48.5%;
        left: 28%;
        color: #FFFFFF;
    }
}

@media only screen and (min-width: 339px) and (max-width: 369px) {    
    .cont {
        padding-right:15%;
        padding-left:15%;
    }
    .price{
        font-family: Inter;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.73px;
        color: black;
        position: absolute;
        top: 51.5%;
        left: 28%;
        color: #FFFFFF;
    }
}

@media only screen and (min-width: 370px) and (max-width: 399px) {    
    .cont {
        padding-right:15%;
        padding-left:15%;
    }
    .price{
        font-family: Inter;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.73px;
        color: black;
        position: absolute;
        top: 55.5%;
        left: 28%;
        color: #FFFFFF;
    }
}

@media only screen and (min-width: 400px) and (max-width: 429px) {    
    .cont {
        padding-right:15%;
        padding-left:15%;
    }
    .price{
        font-family: Inter;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.73px;
        color: black;
        position: absolute;
        top: 60.5%;
        left: 28%;
        color: #FFFFFF;
    }
}

@media only screen and (min-width: 430px) and (max-width: 499px) { 
    .cont {
        padding-right:15%;
        padding-left:15%;
    }   
    .price{
        font-family: Inter;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.73px;
        color: black;
        position: absolute;
        top: 63.5%;
        left: 28%;
        color: #FFFFFF;
    }
}

@media screen and (min-width: 500px) and (max-width: 699px) { 
    .cont {
        padding-right:15%;
        padding-left:15%;
    }
    .newPageDetail1Section{
        display: block;
        justify-content: space-around;
    }
    
    .price{
        font-family: Inter;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.73px;
        color: black;
        position: absolute;
        top: 71.5%;
        left: 28%;
        color: #FFFFFF;
    }
}

@media screen and (min-width: 700px) and (max-width: 899px) { 
    .cont {
        padding-right:15%;
        padding-left:15%;
    }
    .newPageDetail1Section{
        display: block;
        justify-content: space-around;
    }
    
    .price{
        font-family: Inter;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.73px;
        color: black;
        position: absolute;
        top: 71.5%;
        left: 28%;
        color: #FFFFFF;
    }
}

@media screen and (min-width: 900px) and (max-width: 1499px) { 
    .cont {
        padding-right:15%;
        padding-left:15%;
    }
    .newPageDetail1Section{
        display: flex;
        justify-content: space-around;
    }
    
    .price{
        font-family: Inter;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.73px;
        color: black;
        position: absolute;
        top: 71.5%;
        left: 28%;
        color: #FFFFFF;
    }

    .contBottom {
        margin-bottom: 50px;
    }
}

@media screen and (min-width: 1500px) and (max-width: 2500px) { 
    .cont {
        padding-right:15%;
        padding-left:15%;
    }
    .newPageDetail1Section{
        display: flex;
        justify-content: space-around;
    }
    
    .price{
        font-family: Inter;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.73px;
        color: black;
        position: absolute;
        top: 71.5%;
        left: 28%;
        color: #FFFFFF;
    }

    .contBottom {
        margin-bottom: 50px;
    }
}


.newPageDetail1H2{
  font-family: Inter;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  height: 19px;
  top: 322px;
  left: 805px;
}

.newPageBox{
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px;
}

.h1B{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #00699F;
}
